import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    searchKey: String,
    displayKey: String,
    orderBy: String,
    dropdownParent: String,
    filters: Object,
  }

  connect() {
    const options = {}
    if (this.hasDropdownParentValue) options["dropdownParent"] = $(this.dropdownParentValue)
    if (this.urlValue) options["ajax"] = this.ajax()

    $(this.element).select2(options)
  }

  ajax() {
    const searchKey = this.searchKeyValue || "q[name_contains]"
    const displayKey = this.displayKeyValue || "name"
    const orderBy = this.orderByValue || "id"

    return {
      url: this.urlValue,
      dataType: "json",
      data: ((params) => {
        const query = {
          [searchKey]: params.term,
          page: params.page || 1,
          per_page: 10,
          order: orderBy,
          ...(this.hasFiltersValue ? this.filtersValue : {}),
        }

        return query;
      }).bind(this),
      processResults: (data) => {
        return {
          results: data.map((record) => ({ id: record.id, text: record[displayKey] })),
          pagination: {
            more: data.length >= 10,
          }
        };
      }
    }
  }
}

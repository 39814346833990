import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = [ "Slider", "SliderSection","BtnRight","BtnLeft" ]

  next() {
    const Slider = this.SliderTarget;
    let SliderSection = this.SliderSectionTargets
    let SliderSectionLast = SliderSection[SliderSection.length - 1] ; 
    Slider.style.marginLeft = "0%";
    Slider.style.transition = "all 0.3s";
    setTimeout(() => {
      Slider.insertAdjacentElement('afterBegin',SliderSectionLast);
      Slider.style.transition = "none";
      Slider.style.marginLeft = "-100%";
    }, 300);
  }
  previous() {
    const Slider = this.SliderTarget;
    let SliderSection = this.SliderSectionTargets
    let SliderSectionFirst = SliderSection[0];
    Slider.style.marginLeft = "-200%";
    Slider.style.transition = "all 0.3s";
    setTimeout(() => {
      Slider.insertAdjacentElement('beforeEnd',SliderSectionFirst);
      Slider.style.transition = "none";
      Slider.style.marginLeft = "-100%";
    }, 300);
  }

}

import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

export default class extends Controller {
  static targets = [
    "container",
    "category",
    "court",
    "modal",
    "categorySelect",
    "matchSelect",
    "courtSelect",
    "startsAt",
    "alert",
  ]

  static values = {
    url: String,
    matchUrl: String,
    matchScheduleUrl: String,
  }

  connect() {
    this.calendar = new Calendar(this.containerTarget, {
      plugins: [timeGridPlugin, interactionPlugin],
      initialView: 'timeGridWeek',
      views: {
        timeGridFourDay: {
          type: 'timeGrid',
          duration: { days: 4 },
          buttonText: '4 day',
        },
        timeGridThreeDay: {
          type: 'timeGrid',
          duration: { days: 3 },
          buttonText: '3 day',
        },
      },
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek,timeGridFourDay,timeGridThreeDay,timeGridDay',
      },
      slotMinTime: "06:00:00",
      allDaySlot: false,
      displayEventTime: false,
      events: async (info, successCallback, failureCallback) => {
        try {
          const response = await $.ajax({
            url: this.urlValue,
            method: 'get',
            data: {
              start: info.start,
              end: info.end,
              category_ids: this.categoryTargets.filter((el) => el.checked).map((el) => el.value),
              court_ids: this.courtTargets.filter((el) => el.checked).map((el) => el.value),
            }
          })

          successCallback(response.matches)
        } catch(err) {
          failureCallback(err)
        }
      },
      dateClick: this.onDateClick.bind(this),
      eventContent: this.renderEvent,
      eventClick: this.onEventClick.bind(this),
    })

    $(this.categorySelectTarget).on("change", this.onCategorySelectChange.bind(this))

    this.calendar.render()
  }

  onCategorySelectChange(clearMatchSelection = true) {
    this.matchSelectTarget.dataset.select2FiltersValue = JSON.stringify({ category_id: this.categorySelectTarget.value })
    if (clearMatchSelection) $(this.matchSelectTarget).val("").trigger("change")
  }

  matchUrlFor(categoryId, matchId) {
    return `${this.matchUrlValue.replace("CATEGORY_ID", categoryId).replace("MATCH_ID", matchId)}.json`
  }

  matchScheduleUrlFor(categoryId, matchId) {
    return `${this.matchScheduleUrlValue.replace("CATEGORY_ID", categoryId).replace("MATCH_ID", matchId)}`
  }

  async saveEvent() {
    const categoryId = this.categorySelectTarget.value
    const matchId = this.matchSelectTarget.value
    const courtId = this.courtSelectTarget.value
    const startsAt = this.startsAtTarget.value

    if (categoryId && matchId && courtId && startsAt) {
      const response = await $.ajax({
        url: this.matchScheduleUrlFor(categoryId, matchId),
        method: "POST",
        data: {
          court_id: courtId,
          starts_at: startsAt,
        },
      })

      this.fetchEvents()
      this.alertTarget.innerHTML = `
        <div class="alert alert-${response.success ? 'success' : 'danger'} fade show alert-dismissible" role="alert">
          ${response.success || response.error}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      `
      $(this.modalTarget).modal("hide")
    } else {
      alert("There are some empty fields. Please, fill them all!")
    }
  }

  renderEvent(arg) {
    return { html: arg.event.title }
  }

  render() {
    this.calendar.render()
  }

  fetchEvents() {
    this.calendar.refetchEvents()
  }

  onDateClick(info) {
    $(this.startsAtTarget).val(info.dateStr.substr(0,16))
    this.onCategorySelectChange()
    $(this.courtSelectTarget).val("").trigger("change")
    $(this.modalTarget).modal("show")
  }

  onEventClick(info) {
    const option = new Option(info.event.extendedProps.text, info.event.id, true, true)

    $(this.categorySelectTarget).val(info.event.extendedProps.categoryId).trigger("change")
    $(this.matchSelectTarget).html(option).trigger("change")
    $(this.courtSelectTarget).val(info.event.extendedProps.courtId).trigger("change")
    this.startsAtTarget.value = info.event.startStr.substr(0, 16)

    this.onCategorySelectChange(false)
    $(this.modalTarget).modal("show")
  }
}

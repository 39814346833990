import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    formats: Object,
    matchFormatId: Number,
    scoresHash: Object,
    team1Id: Number,
    team2Id: Number,
    url: String,
  }
  static targets = ["team1Score", "team2Score", "alert", "buttons", "team1", "team2"]

  connect() {
    this.display()
  }

  addScoreForTeam1() {
    this.addScore(true)
  }

  addScoreForTeam2() {
    this.addScore()
  }

  display() {
    let scoreTeam1HTML = ""
    let scoreTeam2HTML = ""

    $(this.team1Target).removeClass("text-success")
    $(this.team2Target).removeClass("text-success")
    if(this.scoresHashValue.winner_id == this.team1IdValue) {
      $(this.team1Target).addClass("text-success")
    } if(this.scoresHashValue.winner_id == this.team2IdValue) {
      $(this.team2Target).addClass("text-success")
    }

    this.scoresHashValue.match_scores_attributes?.forEach((ms) => {
      scoreTeam1HTML += `<span class="ps-2">${ms.team1_score}</span>`
      scoreTeam2HTML += `<span class="ps-2">${ms.team2_score}</span>`
    })
    const lastScore = this.scoresHashValue.match_scores_attributes?.[this.scoresHashValue.match_scores_attributes.length - 1]
    if (lastScore?.match_scores_attributes?.length > 0) {
      const game = lastScore.match_scores_attributes[lastScore.match_scores_attributes.length - 1]
      if (!game.winner_id) {
        scoreTeam1HTML += `<span class="ps-2 text-secondary">${game.team1_score}</span>`
        scoreTeam2HTML += `<span class="ps-2 text-secondary">${game.team2_score}</span>`
      }
    }
    this.team1ScoreTarget.innerHTML = scoreTeam1HTML
    this.team2ScoreTarget.innerHTML = scoreTeam2HTML

    if (this.hasButtonsTarget) {
      if (this.scoresHashValue.winner_id) {
        $(this.buttonsTarget).hide()
        $(this.buttonsTarget).removeClass("d-flex")
      }
      else {
        $(this.buttonsTarget).show()
        $(this.buttonsTarget).addClass("d-flex")
      }
    }
  }

  order() {
    return new Date().getTime()
  }

  addScore(forTeam1) {
    if (this.scoresHashValue.winner_id) return

    if(!this.scoresHashValue.match_format_id) {
      this.scoresHashValue = { match_format_id: this.matchFormatIdValue, team1_score: 0, team2_score: 0, sort: this.order() }
    }

    const score = this.addScorePoint(this.scoresHashValue, forTeam1)
    this.scoresHashValue = score

    this.display()
  }

  createScore(score, attrs) {
    score.match_scores_attributes ||= []
    const newScore = { ...attrs, sort: this.order() }
    score.match_scores_attributes.push(newScore)

    return newScore
  }

  addScorePoint(score, forTeam1) {
    if (score.winner_id) return

    const teamId = forTeam1 ? this.team1IdValue : this.team2IdValue
    const formatId = this.calculateMatchFormatId(score, score.team1_score, score.team2_score)
    let attrs = { match_format_id: formatId }

    if(formatId) {
      const currentPoint = score.match_scores_attributes?.find((ms) => !ms.winner_id) || this.createScore(score, { ...attrs, team1_score: 0, team2_score: 0 })
      this.addScorePoint(currentPoint, forTeam1)
    } else {
      attrs = {
        ...attrs,
        team1_score: forTeam1 ? 1 : 0,
        team2_score: forTeam1 ? 0 : 1,
        winner_id: teamId,
      }
      this.createScore(score, attrs)
    }

    // calculate winner
    const team1Count = score.match_scores_attributes.filter((ms) => ms.winner_id == this.team1IdValue).length
    const team2Count = score.match_scores_attributes.filter((ms) => ms.winner_id == this.team2IdValue).length
    score.team1_score = team1Count
    score.team2_score = team2Count
    const min = Math.min(team1Count, team2Count)
    const max = Math.max(team1Count, team2Count)
    const format = this.formatsValue[score.match_format_id]

    if (max >= format.min_points && (max - min > 1 || (max - min > 0 && (!format.advantage || format.tie_break_format_id && max > format.tie_break_on)))) {
      score.winner_id = teamId
    }

    return score
  }

  calculateMatchFormatId(score, team1_points, team2_points) {
    const format = this.formatsValue[score.match_format_id]
    const min = Math.min(team1_points, team2_points)
    const max = Math.max(team1_points, team2_points)

    if (!format.advantage || !format.tie_break_format_id || max < format.tie_break_on || max > min) {
      return format.points_format_id
    }

    return format.tie_break_format_id
  }

  undoLastPoint(event, score = this.scoresHashValue, idx = 0) {
    if (score.match_scores_attributes?.length > 0) {
      let lastScore = this.undoLastPoint(event, score.match_scores_attributes[score.match_scores_attributes.length - 1], idx + 1)

      if (lastScore.delete) score.match_scores_attributes.splice(-1, 1)
      const winnerId = score.winner_id
      score.team1_score -= lastScore.team1_score
      score.team2_score -= lastScore.team2_score
      score.winner_id = null

      if (idx === 0){
        this.scoresHashValue = score
        this.display()
      } else {
        return {
          team1_score: this.team1IdValue === winnerId ? 1 : 0,
          team2_score: this.team2IdValue === winnerId ? 1 : 0,
          delete: score.match_scores_attributes.length === 0,
        }
      }
    } else {
      return { ...score, delete: true }
    }
  }

  async save() {
    const response = await $.ajax({
      url: this.urlValue,
      method: "post",
      data: {
        match_score: this.scoresHashValue,
      }
    })

    this.alertTarget.innerHTML = `
      <div class="alert alert-${response.success ? 'success' : 'danger'} fade show alert-dismissible" role="alert">
        ${response.success || response.error}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    `

    if (response.success) {
      window.location.reload()
    }
  }
}

// Import and register all your controllers from the importmap under controllers/*

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// Eager load all controllers defined in the import map under controllers/**/*_controller
const context = require.context("./", true, /\.js$/)
application.load(definitionsFromContext(context))

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)


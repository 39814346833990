import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["expand", "contract"]

  expand() {
    $(this.expandTarget).hide()
    $(this.contractTarget).show()
    $(this.element).addClass("fullscreen")
  }

  contract() {
    $(this.expandTarget).show()
    $(this.contractTarget).hide()
    $(this.element).removeClass("fullscreen")
  }
}
